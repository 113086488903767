<template>
	<div id="rootVM" v-cloak>
		<div id="pageTitle">
			<b>모범사례</b>
		</div>
		<!-- Search -->
		<div class="panel panel-flat">
			<div class="panel-body" style="padding-left: 250px; margin-top: 20px; margin-bottom: 0px">
				<form action="#" @submit.prevent="search" @keyup.enter.prevent="search">
					<div class="row">
						<div class="col-md-3" style="width: 300px">
							<div class="form-group">
								<label>{{ detailsFieldMap.objectId }}</label>
								<input
									type="text"
									id="startInput"
									class="form-control"
									:placeholder="detailsFieldMap.objectId"
									v-model="searchVM.objectId"
									@keyup="checkItHasOnlyCapital('objectSearch', $event)"
								/>
							</div>
						</div>
						<div class="col-md-3">
							<div class="form-group">
								<label>{{ detailsFieldMap.targetId }}</label>
								<input
									type="text"
									class="form-control"
									:placeholder="detailsFieldMap.targetId"
									v-model="searchVM.targetId"
									@keyup="checkItHasOnlyCapital('targetSearch', $event)"
								/>
							</div>
						</div>
						<div class="col-md-3" style="width: 370px">
							<div class="form-group">
								<label>{{ detailsFieldMap.lawClss }}</label>
								<input
									type="text"
									style="width: 220px !important"
									class="form-control"
									:placeholder="detailsFieldMap.lawClss"
									v-model="searchVM.lawClss"
								/>
							</div>
						</div>
						<div class="mb-10 text-right" style="float: right">
							<button type="submit" class="btn btn-labeled bg-primary">
								<b><i class="icon-search4"></i></b>
								검색
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>

		<div>
			<div class="mb-10 text-right">
				<button type="button" class="btn btn-labeled bg-teal mx-sm-1" @click="downloadExcel">
					<b><i class="icon-file-excel"></i></b>
					엑셀다운로드
				</button>
				<button v-if="userRoleCd == '00'" type="button" class="btn btn-labeled bg-primary" @click="startCreate">
					<b><i class="icon-plus3"></i></b>
					추가
				</button>
			</div>
			<!-- Grid -->
			<KendoGrid
				ref="grid"
				:auto-bind="false"
				:api-url="apiUrl.pageListApi"
				:columns="gridColumns"
				:apply-search-condition="applySearchStateOnGridLoad"
				@selected-row-item-changed="selectedRowItemChanged"
			></KendoGrid>

			<div class="mt-10 mb-15 text-right"></div>
		</div>

		<!-- 추가 및 상세페이지 -->
		<div id="detailsForm" action="#" class="form-horizontal form-validate-jquery" v-show="openDetailsForm == true">
			<div class="panel panel-flat">
				<div class="panel-heading">
					<div style="display: flex; justify-content: center">
						<!-- 대상물 설정 -->
						<div>
							<span>대상물</span>
							<input type="text" class="form-control" :value="detailsItem.objectId" :disabled="true" />
							<input type="text" class="form-control" :value="detailsItem.objectNm" :disabled="true" />
							<button
								class="btn btn-primary"
								@click="showPopupSearch('object')"
								data-toggle="modal"
								data-target="#objectSearch"
								:disabled="isEditMode == true"
							>
								<i class="fa fa-search"></i>
							</button>
						</div>
						<!-- 조치대상 설정 -->
						<div>
							<span>조치대상</span>
							<input type="text" class="form-control" :value="detailsItem.targetId" :disabled="true" />
							<input type="text" class="form-control" :value="detailsItem.targetNm" :disabled="true" />
							<button
								class="btn btn-primary"
								@click="showPopupSearch('target')"
								data-toggle="modal"
								data-target="#targetSearch"
								:disabled="isEditMode == true"
							>
								<i class="fa fa-search"></i>
							</button>
						</div>
					</div>
				</div>

				<!-- 모범사례 영역 -->
				<div class="panel-body">
					<div class="panel panel-flat">
						<div class="panel-heading">모범사례</div>

						<div class="panel-body">
							<!-- 보여줄 이미지 -->
							<div align="center" style="margin-bottom: 15px">
								<img :src="imageSrc" style="max-width: 100%; height: 300px; object-fit: contain" />
							</div>
							<!-- 파일 첨부용-->
							<input
								type="file"
								id="file"
								ref="file"
								accept="image/*"
								multiple=""
								file=""
								class="file-styled hidden"
								v-on:change="handleFileUpload()"
							/>
							<!-- 파일첨부 버튼. type=file element를 클릭하도록 우회 처리 -->
							<button v-if="userRoleCd == '00'" class="btn btn-primary col-md-12" @click="clickFileBtn" style="margin-bottom: 15px">파일첨부</button>
							<!-- 파일 데이터를 보여줄 테이블 -->
							<div class="table-wrapper" ref="tableWrapperImage">
								<table class="table">
									<thead>
										<tr>
											<th>일자</th>
											<th>파일명</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										<tr
											v-for="(row, index) in detailsItem.images"
											:key="index"
											@click="clickRowImage(index)"
											v-bind:class="{ active: activeImage == index }"
										>
											<td>{{ row.regDtm.slice(0, 10) }}</td>
											<td>{{ row.imgOriNm }}</td>
											<td style="padding-left: 0px; padding-right: 0px">
												<button class="btn btn-xs btn-default" @click.stop="delImage(index)" :disabled="userRoleCd != '00'">
													<i class="fa fa-minus"></i>
												</button>
											</td>
										</tr>
										<tr empty>
											<td colspan="3">추가된 이미지가 없습니다</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
					<!-- 법령 입력 -->
					<div class="panel panel-flat law-panel">
						<div class="panel-heading">관련법령</div>

						<div class="panel-body">
							<div class="row" style="padding-left: 10px">
								<div style="display: flex; align-items: center; margin-bottom: 15px">
									<label style="width: 70px">법조항</label>
									<input
										class="form-control"
										id="lawClss"
										type="text"
										v-model="detailsItem.lawClss"
										:disabled="isNotExistsLaw || userRoleCd != '00'"
									/>
								</div>

								<label>법령</label>
								<textarea class="form-control" v-model="detailsItem.stte" :disabled="isNotExistsLaw || userRoleCd != '00'"></textarea>
								<!-- 본사관리자인 경우에만 버튼 생성하도록 -->
								<div v-if="userRoleCd == '00'" class="btn-wrapper">
									<button class="btn btn-primary" @click="createLaw">추가하기</button>
									<button class="btn btn-primary" @click="saveLaw">저장하기</button>
								</div>
								<!-- 법령 데이터를 보여줄 테이블 -->
								<div class="table-wrapper" ref="tableWrapperLaw">
									<table class="table">
										<thead>
											<tr>
												<th>일자</th>
												<th>법조항</th>
												<th></th>
											</tr>
										</thead>
										<tbody>
											<tr
												v-for="(row, index) in detailsItem.laws"
												:key="index"
												:id="'laws_' + index"
												@click="clickRowLaw(index)"
												v-bind:class="{ active: activeLaw == index }"
											>
												<td>{{ row.regDtm.slice(0, 10) }}</td>
												<td>{{ row.lawClss }}</td>
												<td style="padding-left: 0px; padding-right: 8px">
													<button class="btn btn-xs btn-default" @click.stop="delLaw(index)" :disabled="userRoleCd != '00'">
														<i class="fa fa-minus"></i>
													</button>
												</td>
											</tr>
											<tr empty>
												<td colspan="3">추가된 법령이 없습니다</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="btn-wrapper" align="right">
					<div class="text-right" v-if="!isEditMode">
						<button type="button" class="btn btn-labeled mx-sm-1" @click="closeDetails">
							<b><i class="icon-cross"></i></b>
							닫기
						</button>
						<button type="button" class="btn btn-labeled bg-primary" @mousedown="createData">
							<b><i class="icon-checkmark3"></i></b>
							저장
						</button>
					</div>
					<div class="text-right" v-if="isEditMode">
						<button type="button" class="btn btn-labeled mx-sm-1" @click="closeDetails">
							<b><i class="icon-cross"></i></b>
							닫기
						</button>
						<button type="button" class="btn btn-labeled bg-warning mx-sm-1" @click="deleteData" v-if="userRoleCd == '00'">
							<b><i class="icon-minus3"></i></b>
							삭제
						</button>
						<button type="button" class="btn btn-labeled bg-primary" @mousedown="updateData" v-if="userRoleCd == '00'">
							<b><i class="icon-checkmark3"></i></b>
							저장
						</button>
					</div>
				</div>
			</div>
		</div>
		<!-- 대상물 검색용 모달 -->
		<div id="objectSearch" class="modal fade">
			<div class="modal-dialog modal-md">
				<div class="modal-content">
					<div class="modal-header">
						<button type="button" class="close" data-dismiss="modal">&times;</button>
						<h5 class="modal-title">대상물 검색</h5>
					</div>
					<div class="modal-body">
						<div class="search">
							<input
								type="search"
								class="form-control"
								placeholder="검색어 입력"
								v-model="searchVM.popupSearch"
								@keyup.enter="popupSearch('object')"
							/>
							<button class="btn btn-primary" @click="popupSearch('object')">
								<i class="fa fa-search"></i>
								검색
							</button>
						</div>
						<div class="table-wrapper" ref="tableWrapperObject">
							<table class="table table-hover">
								<thead>
									<tr>
										<th>대상물ID</th>
										<th>대상물명</th>
										<th>대상물 영문명</th>
										<th>메모</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="row in tableDataPopup" :key="row.objectId" v-on:click="clickPopupRowObject(row.objectId, row.objectNm)">
										<td>{{ row.objectId }}</td>
										<td>{{ row.objectNm }}</td>
										<td>{{ row.objectEngNm }}</td>
										<td>{{ row.memo }}</td>
									</tr>
									<tr empty>
										<td colspan="4">등록된 조치대상이 없습니다</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 조치대상 검색용 모달 -->
		<div id="targetSearch" class="modal fade">
			<div class="modal-dialog modal-md">
				<div class="modal-content">
					<div class="modal-header">
						<button type="button" class="close" data-dismiss="modal">&times;</button>
						<h5 class="modal-title">조치대상 검색</h5>
					</div>
					<div class="modal-body">
						<div class="search">
							<input type="search" class="form-control" placeholder="검색어 입력" v-model="searchVM.popupSearch" @keyup.enter="popupSearch" />
							<button class="btn btn-primary" @click="popupSearch">
								<i class="fa fa-search"></i>
								검색
							</button>
						</div>

						<div class="table-wrapper" ref="tableWrapperTarget">
							<table class="table table-hover">
								<thead>
									<tr>
										<th>조치대상ID</th>
										<th>조치대상명</th>
										<th>조치대상 영문명</th>
										<th>메모</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="row in tableDataPopup" :key="row.targettId" v-on:click="clickPopupRowTarget(row.targetId, row.targetNm)">
										<td>{{ row.targetId }}</td>
										<td>{{ row.targetNm }}</td>
										<td>{{ row.targetEngNm }}</td>
										<td>{{ row.memo }}</td>
									</tr>
									<tr empty>
										<td colspan="4">등록된 조치대상이 없습니다</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import KendoGrid from '@/components/KendoGrid';
import _ from 'lodash';
import apiIndex from '../../api/index';
import queryString from 'querystring';
const exemplaryCaseUrl = apiIndex.exemplaryCase;

let axiosExtention;

// temp
const contextPath = '';

//API목록
const apiUrl = {
	excelApi: contextPath + '/system/commcodemng/excel',
	pageListApi: exemplaryCaseUrl.inqExemplaryCase,
	detailsApi: contextPath + '/system/commcodemng/api/details',
};

//검색VM
const searchVM = {
	objectId: '',
	targetId: '',
	lawClss: '',
	popupSearch: '',
};

//그리드설정
const gridColumns = [
	{ field: 'objectId', title: '대상물코드', width: '8%', align: 'center' },
	{ field: 'objectNm', title: '대상물명', width: '18%', overFlowHidden: true },
	{ field: 'targetId', title: '조치대상코드', width: '8%', align: 'center' },
	{ field: 'targetNm', title: '조치대상명', width: '18%', overFlowHidden: true },
	{ field: 'lawClss', title: '법조항', width: '45%', overFlowHidden: true },
	{ field: 'regUser', title: '등록자', width: '8%' },
	{ field: 'regDtm', title: '등록일자', align: 'center', dataFormat: 'YYYY-MM-DD', width: '8%' },
];

//상세필드명맵
const detailsFieldMap = {
	objectId: '대상물코드(명)',
	targetId: '조치대상코드(명)',
	lawClss: '관련법조항',
};

//상세필드검증규칙
const detailsValidationRule = {
	common: {
		//공통
		mstCode: 'required|max:45',
		commonCode: 'required|max:45',
		commonCodeName: 'required|max:45',
		reservedValue1: 'max:200',
		displayOrder: 'required',
		closed: 'required',
		memo: 'max:4000',
	},
};

// const detailsUploadItem = {};
const activeImage = 0;
const activeLaw = 0;

_.extend(detailsValidationRule.edit, detailsValidationRule.common);
_.extend(detailsValidationRule.create, detailsValidationRule.common);

export default {
	components: {
		KendoGrid,
	},
	data: () => ({
		apiUrl: apiUrl,
		searchVM,
		//적용된 검색조건
		searchState: {},
		gridColumns: gridColumns,
		detailsFieldMap,
		//상세편집 원본
		detailsItemOriginal: {},
		//상세편집 수정본
		detailsItem: {},
		detailsUploadItem: {},
		imageSrc: '/images/noimage.png',
		tableDataPopup: [],
		activeImage,
		activeLaw,
		isEditMode: true,
		openDetailsForm: false,
		isNotExistsLaw: true,
		splicedImageRegTime: '',
		userRoleCd: '',
	}),
	computed: {},
	created() {
		// 기본 검색정보 설정
		this.searchVM.objectId = this.$route.query.objectId;
		this.searchVM.targetId = this.$route.query.targetId;
	},
	mounted() {
		this.userRoleCd = JSON.parse(sessionStorage.getItem('loginUserInfo')).userRoleCd;
		// get param이 있으면 조회
		if (typeof this.searchVM.objectId !== 'undefined' || typeof this.searchVM.targetId === 'undefined') {
			this.search();
		}
		// 예외처리용
		axiosExtention = this.$jarvisExtention.axiosExtention;
		// DOM이 다 생성되면 첫번째 검색창에 포커스
		this.$nextTick(function () {
			this.focusFirstInput('startInput');
		});
	},
	watch: {
		detailsItem(newVal) {
			this.$validator.reset(); //Validation상태 초기화

			//details활성화 시 스크롤 이동처리
			if (!_.isEmpty(newVal))
				this.$nextTick(function () {
					window.scrollTo({ top: this.getDetailsFormPositionForScrolling(), behavior: 'smooth' });
				});
		},
	},
	methods: {
		getDetailsFormPositionForScrolling() {
			let element = document.getElementById('detailsForm');
			return element.getBoundingClientRect().top + window.scrollY;
		},
		checkEmptyLaw(index) {
			if (this.detailsItem.laws[index].lawClss == '' && this.detailsItem.laws[index].stte == '') {
				this.clickRowLaw(index);
				document.getElementById('laws_' + index).scrollIntoView();
				return true;
			}
			return false;
		},
		// 빈 법령 row가 있는지 체크
		isThereEmptyLaw() {
			let result = false;
			let lengthOfLaws = this.detailsItem.laws.length;
			for (let i = 0; i < lengthOfLaws; i++) {
				result = this.checkEmptyLaw(i);
				if (result == true) {
					break;
				}
			}
			return result;
		},
		// 대상물 코드(명)이나 조치대상 코드(명) 검색창에 대문자만 있는지 확인
		checkItHasOnlyCapital(data, event) {
			let target = event.target;
			target.value = this.changeSmallLetterToCap(target.value);
			this.putValueInInput(data, target.value);
		},
		// 소문자가 입력되어 있으면 대문자로 변경
		changeSmallLetterToCap(value) {
			let valueArr = value.split('');
			let lengthOfvalue = valueArr.length;
			for (let i = 0; i < lengthOfvalue; i++) {
				if (valueArr[i].charCodeAt() >= 97 && valueArr[i].charCodeAt() <= 122) {
					valueArr[i] = value[i].toUpperCase();
				}
			}
			return valueArr.join('');
		},
		// 검색조건에 넣기
		putValueInInput(data, value) {
			if (data == 'objectSearch') {
				this.searchVM['objectId'] = value;
			} else if (data == 'targetSearch') {
				this.searchVM['targetId'] = value;
			}
		},
		// 검색조건 hidden toggle
		searchVMhidden(e) {
			// click event 에서 target pannel 가져오기
			let pannel = $(e.target).closest('.panel').find('.panel-body')[0];
			if (pannel) {
				if (pannel.style.display === 'none') {
					e.target.classList.remove('rotate-180');
					pannel.style.display = 'block';
				} else {
					e.target.classList.add('rotate-180');
					pannel.style.display = 'none';
				}
			}
		},
		//포커스용
		focusFirstInput(inputId) {
			document.getElementById(inputId).focus();
		},
		// 모범사례 검색 적용 처리
		search() {
			this.searchState = $.extend(true /*deep*/, {}, this.searchVM);
			this.loadGrid();
		},
		// 모범사례 그리드 리스트 불러오기
		loadGrid() {
			this.closeDetails();
			this.$refs.grid.load();
		},
		// 모범사례 그리드 로드 시 검색조건 적용
		applySearchStateOnGridLoad(param) {
			return $.extend(true /*deep*/, param, this.searchState || {});
		},
		// 모범사례 엑셀 다운로드
		downloadExcel() {
			this.$axios({
				url: exemplaryCaseUrl.exlExemplaryCase,
				method: 'POST',
				data: this.searchState,
				responseType: 'blob', // important
			})
				.then(response => {
					let date = new Date();
					// 저장 파일 명 생성
					let fileName = '모범사례_' + date.getFullYear() + ('0' + (1 + date.getMonth())).slice(-2) + ('0' + date.getDate()).slice(-2) + '.xlsx';
					const url = window.URL.createObjectURL(new Blob([response.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', fileName);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
				})
				.catch(axiosExtention.buildErrorHandler());
		},
		// 모범사례 상세화면 닫기
		closeDetails() {
			// $('.plusEx').hide();
			this.detailsItemOriginal = {};
			this.detailsItem = {};
			this.openDetailsForm = false;
			window.scrollTo({ top: 50, behavior: 'smooth' });
		},
		// 모범사례 그리드 행 선택 콜백
		selectedRowItemChanged(selectedRowItem) {
			if (!_.isEmpty(selectedRowItem)) {
				//수정시작
				// $('.plusEx').hide();
				this.openDetailsForm = true;
				this.isEditMode = true;
				this.$axios
					.post(exemplaryCaseUrl.inqOneExemplaryCase, { objectId: selectedRowItem.objectId, targetId: selectedRowItem.targetId })
					.then(
						function (r) {
							this.detailsItem = r.data;
							if (r.data.images.length) {
								this.activeImage = 0;
								this.imageSrc = 'data:image/jpg;base64, ' + r.data.firstImgVO.imgByte;
							} else {
								this.imageSrc = '/images/noimage.png';
							}

							if (r.data.laws.length != 0) {
								this.activeLaw = 0;
								this.isNotExistsLaw = false;
								this.detailsItem.lawClss = r.data.laws[0].lawClss;
								this.detailsItem.stte = r.data.laws[0].stte;
							} else {
								this.isNotExistsLaw = true;
							}
							// this.$refs.popupImg.style.display = 'block';
						}.bind(this),
					)
					.catch(axiosExtention.buildErrorHandler());
			} //상세화면 닫기 (다시로드등으로 선택변경)
			else this.closeDetails();
		},
		// 모범사례 신규 추가 시작
		startCreate() {
			// $('.plusEx').show();
			this.openDetailsForm = true;
			this.isEditMode = false;
			this.imageSrc = '/images/noimage.png';
			this.isNotExistsLaw = true;
			this.detailsItem = { objectId: '', objectNm: '', targetId: '', targetNm: '', images: [], laws: [] };
		},
		// 모범사례 신규 저장
		createData() {
			if (this.isThereEmptyLaw() == true) {
				alert('법령이 비어있습니다.');
				return;
			}
			if (this.detailsItem.objectId && this.detailsItem.targetId) {
				this.$axios.post(exemplaryCaseUrl.insExemplaryCase, this.detailsItem).then(
					function () {
						this.closeDetails();
						this.imageSrc = '/images/noimage.png';
						this.searchState = $.extend(true /*deep*/, {}, this.searchVM);
						this.loadGrid();

						window.scrollTo({ top: 50, behavior: 'smooth' });
					}.bind(this),
				);
			} else {
				return alert('대상물과 조치대상을 선택해 주십시오');
			}
		},
		//모범사례 데이터 수정 처리
		updateData() {
			if (this.isThereEmptyLaw() == true) {
				alert('법령이 비어있습니다.');
				return;
			}
			if (confirm('수정한 데이터를 저장하시겠습니까?')) {
				this.$axios
					.post(exemplaryCaseUrl.updExemplaryCase, this.detailsItem)
					.then(
						function () {
							this.loadGrid();
							window.scrollTo({ top: 50, behavior: 'smooth' });
						}.bind(this),
					)
					.catch(function () {
						alert('수정 처리에 실패했습니다\n관리자에게 문의 바랍니다');
					});
			}
		},
		// 모범사례 행 삭제 처리
		deleteData() {
			if (confirm('이 모범사례를 영구적으로 삭제하시겠습니까?')) {
				var objectId = this.detailsItem.objectId;
				var targetId = this.detailsItem.targetId;

				this.$axios
					.post(exemplaryCaseUrl.delExemplaryCase, { objectId: objectId, targetId: targetId })
					.then(
						function () {
							this.loadGrid();
						}.bind(this),
					)
					.catch(function () {
						alert('삭제에 실패했습니다\n잠시 후 다시 시도해 주십시오');
					});
			}
		},
		/*
		 * 대상물, 조치대상 검색 팝업 및 컨트롤
		 */
		// 검색 팝업 노출, seperator = object 또는 target 구분자
		showPopupSearch(seperator) {
			this.searchVM.popupSearch = '';
			this.getPopuptList(seperator);
		},
		// 팝업 내 검색 버튼 클릭 시 처리
		popupSearch(seperator) {
			this.getPopuptList(seperator);
		},
		// 테이블에 리스트 불러오기
		getPopuptList(seperator) {
			var url = seperator == 'object' ? exemplaryCaseUrl.inqExemplaryCaseObject : exemplaryCaseUrl.inqExemplaryCaseTarget;

			let param = {
				popupSearch: this.searchVM.popupSearch,
			};
			param = queryString.stringify(param);

			this.$axios
				.post(url, param)
				.then(r => {
					if (r.data) this.tableDataPopup = r.data;
					this.$refs.tableWrapperObject.scrollTo({ top: 0, behavior: 'smooth' });
					this.$refs.tableWrapperTarget.scrollTo({ top: 0, behavior: 'smooth' });
				})
				.catch(err => {
					err;
					alert('리스트 조회에 실패하였습니다\n잠시 후 다시 시도해 주십시오');
				});
		},
		// 대상물 선택 콜백
		clickPopupRowObject(objectId, objectNm) {
			if (this.detailsItem.targetId.length) {
				this.$axios
					.post(exemplaryCaseUrl.chkExemplaryCase, { objectId: objectId, targetId: this.detailsItem.targetId })
					.then(
						function (r) {
							let data = r.data;

							if (data.statusCode === 'OK') {
								this.detailsItem.objectId = objectId;
								this.detailsItem.objectNm = objectNm;
								$('#objectSearch').modal('hide');
							} else {
								alert('이 모범사례는 이미 추가되어 있습니다\n새로 추가할 조치대상을 선택해 주십시오');
							}
						}.bind(this),
					)
					.catch(function () {
						alert('이 모범사례는 이미 추가되어 있습니다\n새로 추가할 대상물을 선택해 주십시오');
					});
			} else {
				this.detailsItem.objectId = objectId;
				this.detailsItem.objectNm = objectNm;
				$('#objectSearch').modal('hide');
			}
		},
		// 조치대상 선택 콜백
		clickPopupRowTarget(targetId, targetNm) {
			if (this.detailsItem.objectId.length) {
				this.$axios
					.post(exemplaryCaseUrl.chkExemplaryCase, { objectId: this.detailsItem.objectId, targetId: targetId })
					.then(
						function (r) {
							let data = r.data;

							if (data.statusCode === 'OK') {
								this.detailsItem.targetId = targetId;
								this.detailsItem.targetNm = targetNm;
								$('#targetSearch').modal('hide');
							} else {
								alert('이 모범사례는 이미 추가되어 있습니다\n새로 추가할 조치대상을 선택해 주십시오');
							}
						}.bind(this),
					)
					.catch(function () {
						alert('이 모범사례는 이미 추가되어 있습니다\n새로 추가할 조치대상을 선택해 주십시오');
					});
			} else {
				this.detailsItem.targetId = targetId;
				this.detailsItem.targetNm = targetNm;
				$('#targetSearch').modal('hide');
			}
		},
		/*
		 * 파일 업로드 컨트롤
		 */
		// 파일첨부 버튼을 누르면 type=file element를 클릭하도록 우회 처리
		clickFileBtn() {
			// 대상물과 조치대상이 선택되어 있어야 동작
			if (this.detailsItem.objectId.length && this.detailsItem.targetId.length) this.$refs.file.click();
			else return alert('대상물과 조치대상을 먼저 선택해 주십시오');
		},
		// 파일이 첨부되었을 때 처리
		handleFileUpload() {
			this.detailsUploadItem.uploadFile = this.$refs.file.files[0];
			this.detailsUploadItem.fileName = this.$refs.file.files[0].name;
			this.detailsUploadItem.fileSize = this.$refs.file.files[0].size;

			// this.uploadTemp(0, '');
			this.uploadTemp(0, this.detailsUploadItem.fileName);
		},
		// temp 파일 분할 업로드
		uploadTemp(offset, fileName) {
			var file = this.$refs.file.files[0];
			if (offset >= file.size) {
				// 업로드 완료 후처리
				this.$axios.post(exemplaryCaseUrl.imgMain, { fileName: fileName }).then(response => {
					this.imageSrc = 'data:image/jpg;base64,' + response.data.imgVO.imgByte;
					// 현재 연월일
					var date = new Date();
					var month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
					var ymd = date.getFullYear() + '-' + month + '-' + date.getDate();

					this.detailsItem.images.push({
						regDtm: ymd,
						img: response.data.imgVO.fileName,
						imgOriNm: file.name,
						objectId: this.detailsItem.objectId,
						targetId: this.detailsItem.targetId,
					});
					this.activeImage = this.detailsItem.images.length - 1;
					this.$refs.file.value = '';

					setTimeout(
						function () {
							this.$refs.tableWrapperImage.scrollTo({
								top: this.$refs.tableWrapperImage.scrollHeight,
								behavior: 'smooth',
							});
						}.bind(this),
						100,
					);
				});
			} else {
				var size = Math.min(offset + 1000000, file.size);
				var formData = new FormData();

				formData.append('objectId', this.detailsItem.objectId);
				formData.append('fileName', fileName);
				formData.append('fileSize', file.size);
				formData.append('uploadFile', file.slice(offset, size));

				this.$axios
					.post(exemplaryCaseUrl.uplExemplaryCase, formData, {
						headers: { 'Content-Type': 'multipart/form-data' },
					})
					.then(
						function (r) {
							this.uploadTemp(size, r.data);
						}.bind(this),
					)
					.catch(axiosExtention.buildErrorHandler(this.detailsFieldMap));
			}
		},
		// 이미지 row를 클릭했을 때 이미지 불러오기
		clickRowImage(index) {
			this.activeImage = index;
			this.$axios.post(exemplaryCaseUrl.imgMain, { fileName: this.detailsItem.images[index].img }).then(response => {
				this.imageSrc = 'data:image/jpg;base64,' + response.data.imgVO.imgByte;
			});
		},
		// 이미지 테이블 행 삭제 처리
		delImage(index) {
			this.detailsItem.images.splice(index, 1);
			this.imageSrc = '/images/noimage.png';
			if (this.detailsItem.images.length > 0) {
				index = this.changeIndexToShow(index);
				this.$axios.post(exemplaryCaseUrl.imgMain, { fileName: this.detailsItem.images[index].img }).then(response => {
					this.imageSrc = 'data:image/jpg;base64,' + response.data.imgVO.imgByte;
				});
			}
			this.activeImage = index;
		},

		/*
		 * 관련 법령 컨트롤
		 */
		// 법령 행 추가
		createLaw() {
			// 대상물과 조치대상이 선택되어 있어야 동작
			if (this.detailsItem.objectId.length && this.detailsItem.targetId.length) {
				if (this.detailsItem.laws.length != 0) {
					if (this.isThereEmptyLaw()) {
						alert('먼저 법령을 입력해주세요.');
						return;
					}
				}
				// 현재 연월일
				var date = new Date();
				var month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
				var ymd = date.getFullYear() + '-' + month + '-' + date.getDate();

				this.isNotExistsLaw = false;
				this.detailsItem.lawClss = '';
				this.detailsItem.stte = '';
				this.detailsItem.laws.push({
					regDtm: ymd,
					lawClss: '',
					stte: '',
					objectId: this.detailsItem.objectId,
					targetId: this.detailsItem.targetId,
				});
				this.activeLaw = this.detailsItem.laws.length - 1;
				setTimeout(
					function () {
						this.$refs.tableWrapperLaw.scrollTo({
							top: this.$refs.tableWrapperLaw.scrollHeight,
							behavior: 'smooth',
						});
					}.bind(this),
					100,
				);
			} else {
				return alert('대상물과 조치대상을 먼저 선택해 주십시오');
			}
			this.$nextTick(function () {
				this.focusFirstInput('lawClss');
			});
		},
		// 법령 행 저장
		saveLaw() {
			// 대상물과 조치대상이 선택되어 있어야 동작
			if (this.detailsItem.objectId && this.detailsItem.targetId) {
				if (this.detailsItem.laws.length != 0) {
					this.detailsItem.laws[this.activeLaw].lawClss = this.detailsItem.lawClss;
					this.detailsItem.laws[this.activeLaw].stte = this.detailsItem.stte;
				}
			} else {
				return alert('대상물과 조치대상을 먼저 선택해 주십시오');
			}
		},
		// 법령 row를 클릭했을 때 법령 불러오기
		clickRowLaw(index) {
			this.activeLaw = index;
			this.detailsItem.lawClss = this.detailsItem.laws[index].lawClss;
			this.detailsItem.stte = this.detailsItem.laws[index].stte;
		},
		//테이블에서 row를 삭제했을 때 인덱스 조절용
		changeIndexToShow(index) {
			if (index == 0) {
				return index;
			} else {
				return index - 1;
			}
		},
		// 법령 행 삭제
		delLaw(index) {
			this.detailsItem.laws.splice(index, 1);
			if (this.detailsItem.laws.length != 0) {
				index = this.changeIndexToShow(index);
				// if (!this.detailsItem.laws[index]) index -= 1;
				// index -= 1;
				this.isNotExistsLaw = false;
				this.detailsItem.lawClss = this.detailsItem.laws[index].lawClss;
				this.detailsItem.stte = this.detailsItem.laws[index].stte;
			} else {
				this.isNotExistsLaw = true;
				this.detailsItem.lawClss = '';
				this.detailsItem.stte = '';
			}

			this.activeLaw = index;
		},
	},
};
</script>

<style scoped>
[v-cloak] > * {
	display: none !important;
}
.row .form-group > :last-child {
	width: 50% !important;
	margin-left: 10px;
}
.form-group input {
	display: inline-block;
}

#detailsForm > .panel > .panel-heading > div {
	display: flex;
	margin-bottom: 15px;
}
#detailsForm > .panel > .panel-heading > div > div {
	display: flex;
	align-items: center;
	margin-right: 40px;
}
#detailsForm > .panel > .panel-heading > div > div > * {
	margin-right: 5px;
}
#detailsForm > .panel > .panel-heading span {
	margin-right: 10px;
	min-width: 60px;
}
#detailsForm > .panel > .panel-body {
	margin-bottom: 20px;
}
#detailsForm > .panel > .panel-body > div {
	min-width: 220px;
	height: 650px;
	width: calc(50% - 10px);
	display: inline-flex;
	flex-direction: column;
}
#detailsForm > .panel > .panel-body > div:last-child {
	float: right;
}

#detailsForm .panel-heading {
	font-weight: bold;
}
#detailsForm .panel-heading input {
	max-width: 160px;
}

#detailsForm .law-panel .panel-body textarea {
	height: 220px;
	resize: none;
	margin-bottom: 15px;
}
#detailsForm .law-panel .btn-wrapper {
	padding: 0;
	margin-bottom: 15px;
}
#detailsForm .law-panel .btn-wrapper .btn {
	width: calc(50% - 5px);
	margin: 0;
}
#detailsForm .law-panel .btn-wrapper .btn:last-child {
	float: right;
}
#detailsForm tr.active td {
	background: rgba(233, 230, 230, 0.4);
}
#detailsForm .panel-body .panel-flat {
	margin-bottom: 0px;
}
/* #detailsForm .panel-body .panel-flat { max-height:100px; } */
#detailsForm .panel-body .panel-flat .panel-body {
	display: flex;
	flex-direction: column;
}

#detailsForm .table-wrapper {
	height: 230px;
	/* max-height: 300px; */
	overflow-y: auto;
}
.modal .table-wrapper {
	max-height: 300px;
	overflow-y: auto;
}
.modal .table tbody tr:hover {
	cursor: pointer;
}

.table th {
	background: #eee;
	vertical-align: middle !important;
	font-weight: bold;
}
.panel-body .table th:first-child {
	width: 120px;
	text-align: center;
}

.panel-body .table th:last-child {
	width: 80px;
	text-align: center;
}

.table td {
	vertical-align: middle;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	max-width: 1px;
}
.panel-body .table td:first-child {
	text-align: center;
}
.panel-body .table td:last-child {
	text-align: center;
	width: 80px;
}

.table [empty] {
	display: none;
}
.table [empty] td {
	padding: 30px;
	font-size: 1.2em;
	text-align: center;
}
.table [empty]:first-child:last-child {
	display: table-row;
}

.btn-wrapper {
	width: 100%;
	clear: both;
	padding-bottom: 30px;
	padding-right: 20px;
}

.btn-wrapper .btn {
	margin-left: 10px;
}

.modal .search {
	display: flex;
	align-items: center;
	margin-bottom: 15px;
}
[type='search'] {
	display: inline-block;
	width: 180px;
	margin-right: 5px;
}

input:disabled {
	background: #eee;
}

textarea:disabled {
	background: #eee;
}

@media (max-width: 580px) {
	.row:last-child .form-group > label {
		min-width: 100px;
	}
	#detailsForm > .panel > .panel-heading > div {
		flex-direction: column;
	}
	#detailsForm > .panel > .panel-heading > div > div {
		margin: 0;
	}
	#detailsForm > .panel > .panel-heading > div > div:first-child {
		margin-bottom: 10px;
	}
	#detailsForm > .panel > .panel-body > div {
		width: 100%;
	}
}
</style>

<style scoped>
/*
[v-cloak] > * {
	display: none !important;
}
.row .form-group > :last-child {
	width: 50% !important;
	margin-left: 10px;
}
.form-group input {
	display: inline-block;
}

.popupEx .law-panel .panel-body textarea {
	height: 300px;
	resize: none;
	margin-bottom: 20px;
}
.modal {
	display: none; 
	position: fixed; 
	z-index: 99; 
	left: 0;
	top: 0;
	width: 100%; 
	height: 100%; 
	background-color: rgb(0, 0, 0); 
	background-color: rgba(0, 0, 0, 0.4); 
}
.modal-content {
	background-color: #fefefe;
	margin: 7% auto;
	padding: 20px;
	width: 1300px;
	border: 1px solid #888;
}
.popupEx > div > .panel-heading {
	padding: 0;
	border: none;
	position: relative;
	z-index: 99;
	font-size: 15px;
	font-weight: bold;
}
.popupEx > div > .panel-body {
	padding: 0;
	margin: 0;
	position: relative;
	top: -1px;
}
#law-panel > .panel-body {
	padding: 20px;
	margin: 0;
	height: 640px;
}
#law-panel > .panel-body > .row {
	margin-top: auto;
}
.exCase {
	margin: 0;
	padding: 20px;
}
.popupEx > div > .panel-body > .law-panel {
	display: none;
	margin: 0;
	overflow-y: auto;
}
#ex-caseBtn,
#lawBtn {
	width: 90px;
	height: 35px;
	border: 1px solid #ddd;
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
}
#ex-caseBtn {
	background-color: white;
	border-bottom: 1px solid white;
	color: #333333;
}
#lawBtn {
	background-color: #ebebeb;
	border-bottom: 1px solid #ddd;
	color: #787878;
}

.plusEx > .panel > .panel-heading > div {
	display: flex;
}
.plusEx > .panel > .panel-heading > div > div {
	display: flex;
	align-items: center;
	margin-right: 40px;
}
.plusEx > .panel > .panel-heading > div > div > * {
	margin-right: 5px;
}
.plusEx > .panel > .panel-heading span {
	margin-right: 10px;
	min-width: 60px;
}

.plusEx > .panel > .panel-body > div {
	width: 690px;
	height: 568px;
	float: left;
	margin: 0;
	flex-direction: column;
}
.plusEx > .panel > .panel-body > div:last-child {
	float: right;
}

.plusEx .panel-heading {
	font-weight: bold;
}
.plusEx .panel-heading input {
	max-width: 160px;
}

.plusEx .law-panel .panel-body textarea {
	height: 222px;
	resize: none;
	margin-bottom: 15px;
}
.plusEx .law-panel .btn-wrapper {
	padding: 0;
	margin-bottom: 15px;
}
.plusEx .law-panel .btn-wrapper .btn {
	width: calc(50% - 5px);
	margin: 0;
}
.plusEx .law-panel .btn-wrapper .btn:last-child {
	float: right;
}
.plusEx tr.active td {
	background: #ddd;
}

.plusEx .panel-body .panel-flat .panel-body {
	display: flex;
	flex-direction: column;
}

.plusEx .table-wrapper {
	max-height: 200px;
	overflow-y: auto;
}

.modal .table-wrapper {
	max-height: 300px;
	overflow-y: auto;
}
.modal .table tbody tr:hover {
	cursor: pointer;
}

.table th {
	background: #eee;
	vertical-align: middle !important;
	font-weight: bold;
}
.panel-body .table th:first-child {
	width: 120px;
	text-align: center;
}

.table td {
	vertical-align: middle;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	max-width: 1px;
}
.panel-body .table td:first-child {
	text-align: center;
}
.panel-body .table td:last-child {
	text-align: center;
	width: 80px;
}

.table [empty] {
	display: none;
}
.table [empty] td {
	padding: 30px;
	font-size: 1.2em;
	text-align: center;
}
.table [empty]:first-child:last-child {
	display: table-row;
}

.btn-wrapper {
	width: 100%;
	clear: both;
	padding-bottom: 20px;
	padding-right: 20px;
}
.btn-wrapper .btn {
	margin-left: 10px;
}

.modal .search {
	text-align: center;
	margin-bottom: 15px;
}
.modal-header {
	padding: 0;
}
.close {
	right: 0 !important;
	top: 0 !important;
	font-size: 35px;
	margin-top: -15px !important;
	margin-right: -5px;
}
.modal-body {
	padding: 0;
	padding-top: 20px;
}
[type='search'] {
	display: inline-block;
	width: 330px;
	margin-right: 5px;
}
.modal-dialog > .modal-content {
	width: 800px;
	margin: 15% auto;
}
*/
</style>
